import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  annualRent: string,
  moveInDate: string,
  duration: string,
  openIndex:number | null,
  dateInputRef:any,
  FaqData:{
    id: string,
    attributes: {
      answer: string,
      question: string
    }
  }[],
  whyChooseData:{
    id:number,
    title:string,
    description:string
  },
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      annualRent: "",
      moveInDate: "",
      duration: '6 Months', 
      openIndex: null,
      dateInputRef : React.createRef(),
      FaqData:[],
      whyChooseData:{
        id:0,
        title:"",
        description:""
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    this.handleCalculateInvest(from, message);
    this.handleGetFaq(from, message);
    this.handleWhyChooseUs(from, message);
    // Customizable Area End
  }

  // Customizable Area Start
  calculateDataId: string = "";
  getFaqDataId: string = "";
  getWhyChooseDataId: string = "";

  async componentDidMount(): Promise<void> {
    super.componentDidMount()
    this.getFaqData();
    this.getWhyChooseData();
  }

  handleCalculateInvest = (from: string, message: Message): void => {
    const apiRequestCallID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
    if (this.calculateDataId !== apiRequestCallID) {
      return;
    }
  
    const loginToken = localStorage.getItem('login_token');
    const navigationPath = this.getNavigationPath(loginToken);
  
    if (this.isValidMoveInDate() && this.state.moveInDate >= new Date().toISOString()) {
      this.navigateTo(navigationPath);
    }
  };
  
  getNavigationPath = (loginToken: string | null): string => {
    return loginToken ? "CustomisableUserProfiles" : "Signup";
  };
  
  isValidMoveInDate = (): boolean => {
    return this.state.moveInDate !== "" && this.state.annualRent !== "";
  };
  
  navigateTo = (navigationPath: string): void => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), navigationPath);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };
  
  
  handleGetFaq = (from: string, message: Message) => {
    const apiRequestCallID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if(this.getFaqDataId === apiRequestCallID) {
      if(!responseJson.errors) {
        this.setState({FaqData:responseJson.data})
      }
    }
  }

  handleCondition = (condition: any, truePart: any, falsePart: any) => {
    return condition ? truePart : falsePart
  }
  handleWhyChooseUs = (from: string, message: Message) => {
    const apiRequestCallID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if(this.getWhyChooseDataId === apiRequestCallID) {
      if(!responseJson.errors) {
        this.setState({whyChooseData:responseJson[0]})
      }
    }
  }
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  CalculateSchema = Yup.object().shape({
    annualRent : Yup.string().required("Annual Rent is required"),
  })

  handleSubmitApplication=()=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "CustomisableUserProfiles");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleRentChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const numericValue = event.target.value.replace(
      /\D/g,
      ""
  );
    this.setState({ annualRent: numericValue });
  };

  handleDateChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ moveInDate: event.target.value });
  };

  handleDurationChange = (event:any) => {
    this.setState({ duration: event.target.value });
  };

  getFaqData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const getFaqRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFaqDataId = getFaqRequestMessage.messageId;
    getFaqRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFaqDataEndPoint
    );
    getFaqRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    getFaqRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(getFaqRequestMessage.id, getFaqRequestMessage);
  }

  getWhyChooseData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const getWhyChooseRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getWhyChooseDataId = getWhyChooseRequestMessage.messageId;
    getWhyChooseRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWhyChooseDataEndPoint
    );
    getWhyChooseRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    getWhyChooseRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(getWhyChooseRequestMessage.id, getWhyChooseRequestMessage);
  }

  handleSubmit = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const body = {
        "landing_page": {
          "annual_rent": this.state.annualRent,
          "move_in_date": this.state.moveInDate,
          "duration": this.state.duration.split(" ")[0]
          }
      }
    const calculateDataRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.calculateDataId = calculateDataRequest.messageId;
    calculateDataRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.calculateInvestEndPoint
    );
    calculateDataRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    calculateDataRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    calculateDataRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(calculateDataRequest.id, calculateDataRequest);
  }

  handleFaqToggle=(index:number)=>{
    this.setState({ openIndex: this.state.openIndex === index ? null : index });
  }
  // Customizable Area End
}
