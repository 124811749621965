export const backicon = require("../assets/cuticon.png");
export const caricon = require("../assets/car.png");
export const groupicon = require("../assets/Group.png");
export const passporticon = require("../assets/passport.png");
export const identitycard = require("../assets/identitycard.png");
export const rightarrow = require("../assets/rightarrow.png");
export const leftarrow = require("../assets/Leftarrow.png");
export const docscanicon = require("../assets/docscan.png");
export const camera = require("../assets/camera.png");
export const galleryicon = require("../assets/galleryicon.png");
export const Success = require("../assets/success.png");
export const rejecticon = require("../assets/reject.png");
export const HeaderLogoo = require("../assets/HeaderLogo.svg");
