import React from "react";
// Customizable Area Start
import {
  Box, Typography, Button, Container, TextField, Modal ,IconButton
} from "@mui/material";
import { Close } from '@mui/icons-material';
import { HeaderLogoo } from "./assets";

// Customizable Area End

import KnowYourCustomerKycVerificationController, {
  Props,
} from "./KnowYourCustomerKycVerificationController";
import { Formik } from "formik";
const initialValues = {
  firstNameDefault: "",
  lastNameDefault: "",
  middleNameDefault: "",
  emailStateDefault: "",
  nationalityDefault: "",
  dateOfBirth: "",
  phoneNumber: ""
}

export default class KnowYourCustomerKycVerification extends KnowYourCustomerKycVerificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <Container style={styles.container}>
        <Typography
          variant="h6"
          sx={{ display: 'flex',justifyContent: { xs: 'center', sm: 'flex-start' }, alignItems: 'center', cursor: "pointer" }}
          data-test-id="home-btn-logo"
          onClick={() => this.onPressMenuItem("Home")}
        >
          <img src={HeaderLogoo.default} alt="Header Logo" />
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '80px', width: '100%' }}>

          <Box style={styles.idbox}>
            <Typography style={styles.idboxtext}>Enter your ID Number </Typography>
            <TextField type="text" id="standard-basic" placeholder="National ID or Residency Number (iqama)" color="secondary" variant="standard" style={styles.idinput} value={this.state.inputValue} onChange={this.inputChangeHandler} sx={{
              "& .MuiInput-underline:before": {
                borderBottom: "2px solid #1D36BB",
              },
              "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                borderBottom: "2px solid #1D36BB",
              },
              "& .MuiInput-underline:after": {
                borderBottom: "2px solid #1D36BB",
              },
              "& .MuiInputBase-input::placeholder": {
                fontFamily: 'Poppins, sans-serif',
                marginBottom: "15px",
              },
            }}
              onBlur={this.validateIdType}
            />
            <p style={{ height: "24px", marginTop: 0, marginBottom: "24px" }}> {this.state.error && (
              <span
                style={{
                  color: "red",
                  fontFamily: "Poppins, sans-serif",
                  margin: 0,
                }}
              >
                {this.state.error}
              </span>
            )}</p>
            <Button variant="contained" sx={styles.idbutton} role="button" onClick={this.handleOpenWithValidation}>
              Next
            </Button>
          </Box>
        </Box>

        <div>
          <Modal
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalstyle}>
              <IconButton sx={{ float: 'inline-end' }} onClick={this.handleClose}><Close /></IconButton><br /><br />
              <br/>
              <Typography style={styles.idboxtext} id="modal-modal-title" variant="h6" component="h2">
                Verify with Nafath
              </Typography>
              <Typography sx={{ textAlign: 'center', mt: 2, mb: 4, fontSize: "16px", fontWeight: 400, fontFamily: "fontFamily: 'Poppins, sans-serif'" }} id="modal-modal-description">
                Open your nafath App to verify
              </Typography>
              <Box sx={{ textAlign: "center", mb: 3 }}>
                {this.state.otpValues.map((value: any, index: any) => (
                  <input
                    key={index}
                    type="text"
                    value={value}
                    style={{
                      width: "60px",
                      height: "60px",
                      margin: "auto",
                      borderRadius: "16px",
                      fontSize: "35px",
                      fontWeight: 700,
                      marginRight: "10px",
                      borderColor: "#1D36BB",
                      textAlign: "center",
                      fontFamily: "Poppins, sans-serif",
                      outline: "none", 
                    }}
                    maxLength={1}
                    tabIndex={-1}
                    readOnly
                    onChange={(e) => this.handleInputChange(e, index)}
                  />
                ))}
              </Box>
            </Box>
          </Modal>
        </div>
      </Container>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const modalstyle = {
  position: 'absolute',
  top: {
    xs: '35%',
    sm:'30%',
   md:'47%',
  },
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '90%',
    sm: '80%',
    md: 500
  },
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '6px',
  boxShadow: 24,
  p: 2,
};


const styles = {
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: "#ffffffff",
  },
  idbox: {
    width: '520px',
    height: '230px',
  },
  idboxtext: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 700,
    fontSize: '28px',
    display: 'flex',
    justifyContent: 'center',
    lineHeight: '29.33px',
  },
  idinput: {
    minWidth: '100%',
    height: '35px',
    marginTop: '80px',
    borderColor: '#1D36BB',
    fontFamily: 'Poppins, sans-serif',
  },
  idbutton: {
    minWidth: '100%',
    backgroundColor: '#1D36BB',
    borderRadius: '6px',
    padding: '10px 51px 10px 51px',
    fontWeight: 700,
    textTransform: "capitalize",
    fontSize: "20px",
    fontFamily: 'Poppins, sans-serif',
  },
};
// Customizable Area End
