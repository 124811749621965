// Customizable Area Start
import React from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  Input, TextField, Grid, Snackbar, Alert
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider, styled
} from "@mui/material/styles";
import Loader from "../../../../packages/components/src/Loader.web";

import ContactusController, { Props } from "./ContactusController";

import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { ContactImage, FacebookIcon, InstagramIcon, TwitterIcon, SnapIcon, TiktokIcon, LinkIcon } from './assets';
import Select from "react-select";
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required')
      .max(80, 'Name must not exceed 80 characters')
      .trim(),
    mobileNumber: Yup.string().matches(/^\d+$/, 'Please provide a valid mobile number')
    .required('Please provide a valid mobile number')
      .test('starts-with-zero', 'Phone number must start with 0', (value) => ((value && value.length > 0) && value.startsWith('0')))
      .matches(/^\d{10}$/, 'Phone Number must be at least 10 numbers')
      .trim(),
    type: Yup.string().required('Type is required').trim(),
    message: Yup.string().required('Message is required')
      .min(50, 'Message must be at least 50 characters')
      .max(400, 'Message must not exceed 400 characters')
      .trim(),
  });

const initialValues = {
  name: '',
  mobileNumber: '',
  type: '',
  message: '',
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

// Customizable Area End

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderSocailMedia = () => {
    const { contactDetails } = this.state;

    return (
      <Box>
        {contactDetails.facebook_visible && contactDetails.facebook_url && (
          <img
            width='50px' height='50px'
            src={FacebookIcon}
            alt="facebook"
            onClick={() => this.openLinkInNewTab(contactDetails.facebook_url)}
          />
        )}
        {contactDetails.instagram_visible && contactDetails.instagram_url && (
          <img
            data-test-id="openLinkInNewTabTestId"

            width='50px' height='50px'
            src={InstagramIcon}
            alt="insta"
            onClick={() => this.openLinkInNewTab(contactDetails.instagram_url)}
          />
        )}
        {contactDetails.twitter_visible && contactDetails.twitter_url && (
          <img
            width='50px' height='50px'
            src={TwitterIcon}
            alt="twitter"
            onClick={() => this.openLinkInNewTab(contactDetails.twitter_url)}
          />
        )}
        {contactDetails.snapchat_visible && contactDetails.snapchat_url && (
          <img
            width='50px' height='50px'
            src={SnapIcon}
            alt="snap"
            onClick={() => this.openLinkInNewTab(contactDetails.snapchat_url)}
          />
        )}
        {contactDetails.tiktok_visible && contactDetails.tiktok_url && (
          <img
            width='50px' height='50px'
            src={TiktokIcon}
            alt="tiktok"
            onClick={() => this.openLinkInNewTab(contactDetails.tiktok_url)}
          />
        )}
        {contactDetails.linkedin_visible && contactDetails.linkedin_url && (
          <img
            width='50px' height='50px'
            src={LinkIcon}
            alt="linkedin"
            onClick={() => this.openLinkInNewTab(contactDetails.linkedin_url)}
          />
        )}

      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { sendDataMsg, isSnackbarError, isSnackbarSuccess ,contactDetails } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <div id="main_block">
          <NavigationMenu navigation={this.props.navigation} id="" isFooter={true} >
            <ThemeProvider theme={theme}>
              <Box sx={mainBoxStyle}>
                <Box sx={contactBoxStyle}>
                  <TextWrapper>
                    <Typography sx={startTextStyle} >Get Started</Typography>
                    <Box sx={textGroupStyle}>
                      <Typography sx={textStyle} >Are you a real estate developer, a landlord, or a real estate agent?</Typography>
                      <Typography sx={textStyle}>Are you an existing client and have an inquiry?</Typography>
                      <Typography sx={textStyle}>Are you looking for a property and have some questions for us?
                      </Typography>
                    </Box>
                  </TextWrapper>
                  <StyledImg src={ContactImage} alt="contactus_img" />
                </Box>
                <CustomInputsWrapper >
                 
                  <Formik
                    validationSchema={validationSchema}
                    data-test-id="address-dialog"
                    initialValues={initialValues}
                    onSubmit={(values, actions) => this.handleSubmit(values, actions)}

                  >
                    {({
                      values,
                      touched,
                      errors,
                      handleBlur,
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                      isValid,
                    }) => {
                      return (
                        <StyledForm onSubmit={handleSubmit}>
                          <Grid container spacing={2} >
                            <Grid item xs={12} md={4} >
                              <Field
                                sx={nameStyle}
                                as={TextField}
                                fullWidth
                                variant='standard'
                                name="name"
                                data-test-id="name"
                                placeholder="Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                error={touched.name && Boolean(errors.name)}
                                helperText={<ErrorMessage className="errorMsgSTyle" name="name" />}
                              />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Field
                                sx={mobilenumStyle}
                                as={TextField}
                                fullWidth
                                variant="standard"
                                name="mobileNumber"
                                data-test-id="mobileNumber"
                                placeholder="Mobile Number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.mobileNumber}
                                error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                                helperText={<ErrorMessage className="errorMsgSTyle" name="mobileNumber" data-test-id="phoneError" />}
                              />
                            </Grid>

                            <Grid item xs={12} md={4}  >
                              <StyledSelect
                                data-test-id="styledSelect"
                                fullWidth
                                sx={optonsStyle}
                                name="type"
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderColor: '#fff',
                                    border: 'none',
                                    boxShadow: 'none',
                                    borderBottom: `1px solid ${state.isFocused ? '#000' : '#ccc'}`,
                                    borderRadius: 0,
                                    fontFamily: 'Poppins, sans-serif',
                                    fontSize: '16px',
                                  }),
                                }}
                                onChange={(option: unknown) => setFieldValue('type', option ? (option as { value: string }).value : '')}
                                value={values.type ? { label: values.type, value: values.type } : null}
                                options={[{ label: "Real Estate Developer", value: "Real Estate Developer" }, { label: "Landlord", value: 'Landlord' }, { label: "Real Estate Agent", value: 'Real Estate Agent' }, { label: "Existing Client", value: 'Existing Client' }, { label: "Others", value: 'Others' },]}
                                isSearchable={false}
                                placeholder="Type"
                                classNamePrefix="react-select"
                              />
                              <ErrorMessage data-test-id="typeErrorMsg" name="type">
                                {msg => <div style={{ color: 'rgb(211, 47, 47)', fontFamily: 'Poppins, sans-serif', fontSize: '12px', fontWeight: 400, }}>{msg}</div>}
                              </ErrorMessage>
                            </Grid>
                            <Grid item md={12} xs={12}  >
                              <Field
                                sx={messageStyle}
                                as={Input}
                                fullWidth
                                placeholder="Message"
                                multiline
                                minRows={4}
                                name="message"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.message}
                                error={touched.message && Boolean(errors.message)}
                              />
                              <ErrorMessage data-test-id="msgError" name="message">
                                {msg => <div className="errorMsgSTyle" >{msg}</div>}
                              </ErrorMessage>
                            </Grid>
                            <Grid item md={3} xs={12} >
                              <Button data-test-id="submitBtn" sx={buttonStyle} variant='contained' type="submit" fullWidth >Send</Button>
                            </Grid>
                          </Grid>
                        </StyledForm>
                      );
                    }}
                  </Formik>
                </CustomInputsWrapper>
                <CustomReachUsWrapper>
                  <Typography className="textContainer" >
                    Or you can reach us at
                  </Typography>
                  <Box className="iconsContainer" component='div' >
                  <Box component='span' className="iconsStack">
                    {this.renderSocailMedia()}
      </Box>
                    <Typography className="mobileNumTypography" >{contactDetails.phone_number} </Typography>
                  </Box>
                </CustomReachUsWrapper>
              </Box>


              <Modal
                open={this.state.isVisible}
                onClose={this.hideModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalStyle}>
                  <Box sx={{ minHeight: 250 }}>
                    <Typography variant="subtitle1" gutterBottom component="div">
                      <Typography variant="h6" gutterBottom display="inline">
                        Id:
                      </Typography>{" "}
                      {this.state.activeId}
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom component="div">
                      <Typography variant="h6" gutterBottom display="inline">
                        Name:
                      </Typography>{" "}
                      {this.state.activeName}
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom component="div">
                      <Typography variant="h6" gutterBottom display="inline">
                        Email:
                      </Typography>{" "}
                      {this.state.activeEmail}
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom component="div">
                      <Typography variant="h6" gutterBottom display="inline">
                        Phone Number:
                      </Typography>
                      {this.state.activePhoneNumber}
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom component="div">
                      <Typography variant="h6" gutterBottom display="inline">
                        Description:
                      </Typography>
                      {this.state.activeDescription}
                    </Typography>

                    <Typography variant="subtitle1" gutterBottom component="div">
                      <Typography variant="h6" gutterBottom display="inline">
                        Created At:
                      </Typography>
                      {this.state.activeCreatedAt}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px 0px",
                    }}
                  >
                    <Button
                      data-test-id="btnDeleteContactUs"
                      variant="contained"
                      color="primary"
                      onClick={() => this.deleteContactUs(this.state.activeId)}
                    >
                      Delete
                    </Button>
                    <Button
                      data-test-id="btnCloseModal"
                      variant="contained"
                      onClick={() => this.hideModal()}
                    >
                      Close
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </ThemeProvider>
          </NavigationMenu>
        </div>
        {sendDataMsg && (
          <Snackbar data-test-id="snackBarId"
            anchorOrigin={{ horizontal: "left", vertical: "top" }}
            autoHideDuration={4000}
            onClose={() => this.handleSnackbarself()}
            className="snackbarStyle"
            open={isSnackbarError || isSnackbarSuccess}                      >
            <Alert severity={isSnackbarError ? "error" : "success"}> {sendDataMsg} </Alert>
          </Snackbar>
        )}
        <Loader loading={this.state.isLoader} />
      </StyledEngineProvider>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledForm = styled('form')({
  width: '100%',
  marginBottom: '20px',
  paddingInline: '10px',
  "@media (min-width: 576px)": {
    paddingInline: '15px',
  },
  "@media (min-width: 768px)": {
    paddingInline: '24px',
  },
  "@media (min-width: 1200px)": {
    paddingInline: '99px',
  },
  "@media (min-width: 1536px)": {
    paddingInline: '218px',
  },

  "& .errorMsgSTyle": {
    mt: '6px',
    lineHeight: '36px',
    fontSize: '12px',
    fontWeight: 400,
    color: 'rgb(211, 47, 47)'
  },
});

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const mainBoxStyle = {
  display: "flex",
  justifyContent: "center",
  flexDirection: 'column',
  alignItems: "center",
  width: '100%',
  marginTop: { xs: "82px", md: "138px" },
  padding: { xs: "", lg: "0px 0px", xl: "0px 0px" },
};

const contactBoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  background: '#1d36bb',
  width: '100%',
  paddingTop: '20px',
  gap: '12px',
  fontFamily: 'Poppins, sans-serif',
  padding: { xs: "16px", sm: "24", lg: "0px 99px", xl: "0px 218px" },
  "@media (min-width: 200px) and (max-width: 600px)": {
    flexDirection: 'column',
    alignItems: 'center'
  },
};


const TextWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  color: '#FFF',
  gap: '12px',
  marginTop: '28px',
});

const startTextStyle = {
  fontSize: '24px',
  lineHeight: '36px',
  fontWeight: 400,
  opacity: 0.5,
  fontFamily: 'Poppins, sans-serif'
};

const textGroupStyle = {
  fontSize: '24px',
  lineHeight: '36px',
  fontWeight: 700,
  gap: '16px',
  fontFamily: 'Poppins, sans-serif',
  color: '#FFFFFF'
};
const textStyle = {
  fontSize: '24px',
  lineHeight: '36px',
  fontWeight: 700,
  color: '#FFFFFF'
};


const StyledImg = styled('img')({
  width: '260px',
  "@media (min-width: 576px)": {
    width: '260px',
  },
  "@media (min-width: 768px)": {
    width: '427px',
  },
});

const CustomInputsWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  marginTop: '138px',
  position: 'relative',
  "@media (min-width: 200px) and (max-width: 900px)": {
    marginTop: '37px'
  },
  "& .snackbarStyle": {
    position: 'absolute',
    marginBottom: '50px',
    "@media (min-width: 1280px)": {
      marginLeft: '73px'
    },
    "@media (min-width: 1600px)": {
      marginLeft: '200px'
    },
  },
});

const nameStyle = {
  marginTop: '6px',
  "@media (min-width: 200px) and (max-width: 900px)": {
  },
};

const mobilenumStyle = {
  marginTop: '6px',
  "@media (min-width: 200px) and (max-width: 900px)": {
    marginTop: '24px'
  },
};

const StyledSelect = styled(Select)({
  marginTop: '0px',
  fontFamily: 'Poppins, sans-serif',
  "& .react-select__value-container": {
    padding: "0px",
    fontWeight: 400,
  },
  "& .react-select__dropdown-indicator": {
    padding: '0px'
  },
  "@media (min-width: 200px) and (max-width: 900px)": {
    marginTop: '24px',
    padding: '2px 0px !important'
  },
});

const optonsStyle = {
  fontFamily: 'Poppins, sans-serif',
  fontSize: '16px',
  color: '#0F172A'
}


const messageStyle = {
  marginTop: '68px',
  "@media (min-width: 200px) and (max-width: 900px)": {
    marginTop: '24px'
  },
};

const buttonStyle = {
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 700,
  textTransform: 'unset',
  marginTop: '69px',
  "@media (min-width: 200px) and (max-width: 900px)": {
    marginTop: '54px'
  },
};

const CustomReachUsWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  gap: '32px',
  width: '100%',
  marginTop: '64px',
  marginBottom: '81px',
  fontFamily: 'Poppins, sans-serif',
  "@media (min-width: 576px)": {
    paddingInline: '15px',
  },
  "@media (min-width: 768px)": {
    paddingInline: '24px',
  },
  "@media (min-width: 1200px)": {
    paddingInline: '99px',
  },
  "@media (min-width: 1536px)": {
    paddingInline: '218px',
  },
  "@media (min-width: 600px) and (max-width: 900px)": {
    marginTop: '58px',
    marginBottom: '63px',
    gap: '29px',

  },
  "@media (min-width: 200px) and (max-width: 600px)": {
    marginTop: '88px',
    marginBottom: '110px',
    gap: '27px',

  },
  "& .textContainer": {
    display: 'flex',
    fontWeight: 700,
    fontSize: '56px',
    lineHeight: '72.8px',
    "@media (min-width: 200px) and (max-width: 900px)": {
      fontSize: '24px',
      lineHeight: '31.2px',
      justifyContent: 'center'
    },
  },
  "& .iconsContainer": {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '15px',
    alignItems: 'center',
    flexWrap: 'wrap',
    "@media (min-width: 200px) and (max-width: 900px)": {
      gap: '32px',
      flexDirection: 'column'
    },
  },
  "& .iconsStack": {
    display: 'flex',
    justifyContent: 'center',
    flexDiretion: 'row',
    flexWrap: 'wrap',
    gap: '24px',
  },
  "& .mobileNumTypography": {
    fontSize: '22px',
    lineHeight: '26.63px',
    fontWeight: 600,
    textAlign: 'center'
  }
});
// Customizable Area End
